import {
  SourceListType,
  ContactListType,
  DeFiHoldingType,
  EntityAccountType,
  TotalPositionType,
  CounterpartyTagType,
  EntitySourceListType,
  CounterpartyListType,
  JournalSourceListType,
  CounterpartyDetailType,
  TreasuryBalanceListType,
  DeFiHoldingProtocolType,
  EntityAccountDetailType,
  RecentlyBalanceParamsType,
  TreasuryAccountFilterType,
  TradeCalculatePositionType,
  TreasuryBalanceListItemType,
  DeFiHoldingPositionParamsType,
  DeFiHoldingPositionFilterType,
  TreasuryBalanceVerifyReasonType,
  TradeCalculatePositionFilterType,
  TradeCalculatePositionParamsType,
  TreasuryBalanceVerificationListType,
  TreasuryBalanceVerificationDetailType,
  TreasuryBalanceVerificationTaskStatType,
  TreasuryBalanceVerificationTransferListType,
  TreasuryBalanceVerifyTaskFilterFilterType,
  TreasuryBalanceVerifyTaskFilterFilterItemType
} from '../../../types/AccountsTypes'
/* eslint-disable no-param-reassign */
import ReportsApi from '@/api/ReportsApi'
import AccountsApi from '@/api/AccountsApi'
import BusinessDataApi from '@/api/BusinessDataApi'
import { useTransactionStore } from './transactions'
import { type GridApi } from '@ag-grid-community/core'
import { BusinessDataSourceListType } from '#/BusinessDataTypes'
import { flatMap, sortBy, uniqueId, indexOf, isEqual, forEach } from 'lodash-es'

export const useAccountStore = defineStore({
  id: 'accountStore',
  state: () => {
    const accountState: {
      sourceList: SourceListType
      treasuryBalanceList: TreasuryBalanceListType
      treasuryBalanceSimplificationList: TreasuryBalanceListItemType[]
      tradeCalculatePositionList: TradeCalculatePositionType[]
      accountList: EntityAccountType[]
      accountDetail: EntityAccountDetailType | null
      contactList: ContactListType
      counterpartList: CounterpartyListType
      counterpartTagList: CounterpartyTagType[]
      counterpartyDetail: CounterpartyDetailType | null
      agGridApi: GridApi | null
      isInitTreasuryBalance: boolean
      allSourceList: EntitySourceListType
      journalSourceList: JournalSourceListType
      businessDataSourceList: BusinessDataSourceListType
      entityAccountUsedProviderMap: any
      datetime: string
      treasuryAccountTableLoading: boolean
      accountFilterList: TreasuryAccountFilterType[]
      derivativeFilterList: TradeCalculatePositionFilterType[]
      deFiHoldingList: DeFiHoldingType
      deFiPositionsFilterList: DeFiHoldingPositionFilterType[]
      deFiHoldingProtocolList: DeFiHoldingProtocolType[]
      deFiHoldingTypeList: string[]
      totalPositionList: TotalPositionType[]
      requestedList: RequestUrlInfo[]
      combineMasterSubAccount: boolean
      balanceVerifyTaskListRequestData: any[]
      treasuryBalanceVerifyTaskDetail: TreasuryBalanceVerificationDetailType | null
      treasuryBalanceVerifyTaskList: TreasuryBalanceVerificationListType
      balanceVerifyTransferList: TreasuryBalanceVerificationTransferListType
      treasuryBalanceVerifyTaskStat: TreasuryBalanceVerificationTaskStatType
      treasuryBalanceVerifyReasonList: TreasuryBalanceVerifyReasonType[]
      treasuryBalanceVerifyTaskFilterList: TreasuryBalanceVerifyTaskFilterFilterType[]
    } = {
      sourceList: {
        total: 0,
        list: []
      },
      journalSourceList: {
        total: 0,
        list: []
      },
      businessDataSourceList: {
        total: 0,
        list: []
      },
      allSourceList: {
        total: 0,
        list: []
      },
      treasuryBalanceList: {
        list: [],
        total: {
          currencyList: [],
          totalFC: 0
        }
      },
      treasuryBalanceVerifyTaskList: {
        list: [],
        filteredCount: 0
      },
      treasuryBalanceVerifyTaskDetail: null,
      treasuryBalanceSimplificationList: [],
      isInitTreasuryBalance: false,
      accountDetail: null,
      counterpartTagList: [],
      counterpartyDetail: null,
      contactList: {
        list: [],
        total: 0
      },
      accountList: [],
      tradeCalculatePositionList: [],
      counterpartList: {
        total: 0,
        list: []
      },
      agGridApi: null,
      entityAccountUsedProviderMap: {},
      datetime: '',
      treasuryAccountTableLoading: true,
      accountFilterList: [],
      derivativeFilterList: [],
      deFiPositionsFilterList: [],
      deFiHoldingList: {
        list: [],
        totalCount: {
          totalSupplyBalanceFC: '0',
          totalRewardBalanceFC: '0',
          totalBorrowBalanceFC: '0',
          totalFC: '0'
        }
      },
      deFiHoldingProtocolList: [],
      deFiHoldingTypeList: [],
      totalPositionList: [],
      requestedList: [],
      balanceVerifyTaskListRequestData: [],
      combineMasterSubAccount: false,
      treasuryBalanceVerifyReasonList: [],
      treasuryBalanceVerifyTaskStat: {
        pendingTotal: 0,
        passedTotal: 0
      },
      balanceVerifyTransferList: {
        list: [],
        totalCount: 0
      },
      treasuryBalanceVerifyTaskFilterList: []
    }
    return accountState
  },
  actions: {
    setRequestedList(requestedInfo: RequestUrlInfo) {
      const currentIndex = this.requestedList.findIndex(
        (item: RequestUrlInfo) => item?.url === requestedInfo?.url && isEqual(item?.params, requestedInfo?.params)
      )
      if (currentIndex === -1) {
        this.requestedList.push(requestedInfo)
      } else {
        this.requestedList.splice(currentIndex, 1, requestedInfo)
      }
    },
    /**
     * 获取总持仓列表
     * @param {string} entityId 主体id
     * @param {Object} params
     * @property {string} params.datetime "2024-10-20 00:00:00"
     */
    async fetchTotalPositionList(entityId: string, params: object) {
      try {
        const { data } = await AccountsApi.getTotalPositionList(entityId, params)
        this.totalPositionList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setDatetime(datetime: string) {
      this.datetime = datetime
    },
    /**
     * @description: 设置账户使用的Provider
     * @param {any} entityAccountUsedProviderMap
     */
    setEntityAccountUsedProviderMap(entityAccountUsedProviderMap: any) {
      this.entityAccountUsedProviderMap = entityAccountUsedProviderMap
    },
    /**
     * @description: 切换账户的Provider
     * @param {string} entityId
     * @param {string} entityAccountId
     * @param {string} providerId
     */
    async switchEntityAccountProvider(entityId: string, entityAccountId: string, providerId: string) {
      try {
        const response = await AccountsApi.switchEntityAccountProvider(entityId, entityAccountId, { providerId })
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: 刷新账户余额
     * @param {string} entityId
     * @param {string} entityAccountId
     */
    async refreshEntityAccountBalance(entityId: string, entityAccountId: string) {
      try {
        const response = await AccountsApi.refreshEntityAccountBalance(entityId, entityAccountId)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: 获取账户Provider余额
     * @param {string} entityId
     * @param {string} entityAccountId
     * @param {string} providerId
     */
    async fetchEntityAccountProviderBalance(entityId: string, entityAccountId: string, providerId: string) {
      try {
        const response = await AccountsApi.getEntityAccountProviderBalance(entityId, entityAccountId, providerId)
        return response
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 余额校验任务区间内的交易列表
     * @param {string} entityId 主体id
     * @param {string} treasuryBalancePeriodId 余额校验任务id
     * @param {object} params 请求参数
     * @property {number} params.page 页码
     * @property {number} params.limit 每页数量
     */
    async fetchTreasuryBalanceVerifyTaskTransactionList(
      entityId: string,
      treasuryBalancePeriodId: string,
      params: object
    ) {
      try {
        const { data } = await AccountsApi.getTreasuryBalanceVerifyTaskTransactionList(
          entityId,
          treasuryBalancePeriodId,
          params
        )
        this.balanceVerifyTransferList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取余额校验任务详情
     * @param {string} entityId 主体id
     * @param {string} treasuryBalancePeriodId 余额校验任务id
     */
    async fetchTreasuryBalanceVerifyTaskDetail(entityId: string, treasuryBalancePeriodId: string) {
      try {
        const { data } = await AccountsApi.getTreasuryBalanceVerifyTaskDetail(entityId, treasuryBalancePeriodId)
        this.treasuryBalanceVerifyTaskDetail = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取余额校验解释列表
     * @param {string} entityId 主体id
     */
    async fetchTreasuryBalanceVerifyTaskReasonList(entityId: string) {
      try {
        const { data } = await AccountsApi.getTreasuryBalanceVerifyTaskReasonList(entityId)
        this.treasuryBalanceVerifyReasonList = data
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取余额校验任务列表统计
     * @param {string} entityId 主体id
     */
    async fetchTreasuryBalanceVerifyTaskStat(entityId: string) {
      try {
        const { data } = await AccountsApi.getTreasuryBalanceVerifyTaskStat(entityId)
        this.treasuryBalanceVerifyTaskStat = data
        return Promise.resolve(data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取余额校验任务列表
     * @param {string} entityId 主体id
     * @param {object} params 请求参数
     * @property {number} params.page 页码
     * @property {number} params.limit 每页数量
     * @property {string} params.status 状态 PENDING/PASSED
     * @param {object} data 请求参数
     * @property {string} data.sortDirection 排序方向
     * @property {string} data.sortField 排序字段  beginDatetime | difference | differenceValue
     * @property {string[]} data.entityAccountId 账户id
     * @property {string[]} data.currency 币种
     * @property {Array} data.datetime 时间范围
     * @property {string[]} data.treasuryBalanceReasonId 余额原因id
     */
    async fetchTreasuryBalanceVerifyTaskList(entityId: string, params: object, data: object) {
      try {
        const response = await AccountsApi.getTreasuryBalanceVerifyTaskList(entityId, params, data)
        this.treasuryBalanceVerifyTaskList = response.data
        return Promise.resolve(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * defi 过滤器数据初始化
     * @param {string} entityId 项目id
     */
    async balanceVerifyTasksFilterDataInit(entityId: string) {
      const transactionStore = useTransactionStore()

      try {
        const response = await Promise.allSettled([
          this.fetchEntityAccountList(entityId, { withDeleted: true }),
          transactionStore.fetchTransactionCurrencyList(entityId),
          this.fetchTreasuryBalanceVerifyTaskReasonList(entityId)
        ])
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Source列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchAccountSourceList(entityId: string, params: object) {
      try {
        const response = await AccountsApi.getAccountSourceList(entityId, params)
        this.sourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Journal Source列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchJournalSourceList(entityId: string, params: object) {
      try {
        const response = await AccountsApi.getJournalSourceList(entityId, params)
        this.journalSourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取业务数据Source列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} page
     * @property {number} limit
     */
    async fetchBusinessDataSourceList(entityId: string, params: object) {
      try {
        const response = await BusinessDataApi.getBusinessDataSourceList(entityId, params)
        this.businessDataSourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取全部类型Source列表
     * @param {string} entityId 主体id
     */
    async fetchEntitySourceList(entityId: string) {
      try {
        const response = await ReportsApi.getEntitySourceList(entityId)
        this.allSourceList = response.data
        return response.data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * @description: 设置Ag-Grid的Api
     * @param {GridApi} api
     */
    setAgGridApi(api: GridApi) {
      this.agGridApi = api
    },
    /**
     * 递归地从对象或数组中移除 currencyList 属性
     * 如果输入是一个数组，该函数将对数组中的每个元素调用自身
     * 如果输入是一个对象，该函数将创建一个新的对象，其中包含除 currencyList 之外的所有属性，并对这些属性的值递归调用自身
     * 如果输入既不是数组也不是对象，该函数将直接返回输入值
     *
     * @param obj - 要处理的对象或数组
     * @returns 处理后的对象或数组
     */
    removeCurrencyList(obj: any): any {
      if (Array.isArray(obj)) {
        return obj.map(this.removeCurrencyList)
      }
      if (obj !== null && typeof obj === 'object') {
        const { currencyList, ...rest } = obj
        for (const key in rest) {
          // eslint-disable-next-line no-prototype-builtins
          if (rest?.hasOwnProperty(key)) {
            rest[key] = this.removeCurrencyList(rest[key])
          }
        }
        return rest
      }
      return obj
    },
    /**
     * 获取Account近期余额列表
     * @param {string} entityId 主体id
     * @param {RecentlyBalanceParamsType} data
     * @property {string} data.date 2024-01-01
     * @property {string} data.subGroup NONE | PLATFORM
     * @property {string} [data.auxiliaryTypeId] subGroup不为NONE | PLATFORM必填
     * @property {string} data.sort BALANCE | NAME
     * @property {boolean} data.combineMasterSubAccount 合并主账户和子账户
     */
    async fetchEntityRecentlyBalanceList(entityId: string, params?: RecentlyBalanceParamsType) {
      try {
        const { data } = await AccountsApi.getEntityTreasuryBalance(entityId, params)
        const platformTypeList = ['CHAIN', 'EXCHANGE', 'BANK', 'CUSTODY', 'PAYMENT']
        data.list = sortBy(data.list, (item) => indexOf(platformTypeList, item.platformType))
        if (params?.subGroup === 'NONE' && !params?.combineMasterSubAccount) {
          forEach(data.list, (item: any, firstLevelIndex: number) => {
            item.firstLevelGroup = `${item.platformType}${uniqueId()}`
            item.firstLevelIndex = firstLevelIndex
            item.rowId = uniqueId(`${item.platformType}${Math.floor(Math.random() * 900) + 100}`)
            item.list = item.list?.filter(
              (entityAccountData: any) => entityAccountData.entityAccount?.status === 'NORMAL'
            )
            forEach(item.list, (balanceItem: any, secondLevelIndex: number) => {
              balanceItem.firstLevelIndex = firstLevelIndex
              balanceItem.secondLevelIndex = secondLevelIndex
              this.entityAccountUsedProviderMap[balanceItem.entityAccount.entityAccountId] =
                balanceItem.entityAccount.usedProvider
              balanceItem.rowId = uniqueId(
                `${item.platformType}-${balanceItem.entityAccount.entityAccountId}${Math.floor(Math.random() * 900) + 100}`
              )
            })
          })
        } else {
          forEach(data.list, (item: any, firstLevelIndex: number) => {
            item.rowId = uniqueId(`${item.platformType}${Math.floor(Math.random() * 900) + 100}`)
            item.firstLevelGroup = `${item.platformType}${uniqueId()}`
            item.firstLevelIndex = firstLevelIndex

            let secondLevelRowIndex = 0
            forEach(item.list, (secondLevelGroup: any, secondLevelIndex: number) => {
              secondLevelGroup.firstLevelIndex = firstLevelIndex
              secondLevelGroup.secondLevelIndex = secondLevelIndex
              secondLevelGroup.secondLevelGroup = `${
                secondLevelGroup?.platform?.slug ||
                secondLevelGroup?.counterparty?.name ||
                secondLevelGroup?.auxiliaryItem?.value
              }${uniqueId()}`
              secondLevelGroup.rowId = uniqueId(
                `${item.platformType}-${secondLevelIndex}${Math.floor(Math.random() * 900) + 100}`
              )
              if (secondLevelGroup?.list?.length) {
                secondLevelGroup.list = secondLevelGroup.list?.filter(
                  (entityAccountData: any) => entityAccountData.entityAccount?.status === 'NORMAL'
                )
                forEach(secondLevelGroup?.list, (balanceItem: any, thirdLevelIndex: number) => {
                  secondLevelRowIndex += 1
                  this.entityAccountUsedProviderMap[balanceItem.entityAccount.entityAccountId] =
                    balanceItem.entityAccount.usedProvider
                  balanceItem.firstLevelIndex = firstLevelIndex
                  balanceItem.secondLevelIndex = secondLevelIndex
                  balanceItem.thirdLevelIndex = thirdLevelIndex
                  balanceItem.secondLevelRowIndex = params?.combineMasterSubAccount ? secondLevelRowIndex : null
                  balanceItem.rowId = uniqueId(
                    `${item.platformType}-${secondLevelIndex}-${balanceItem.entityAccount.entityAccountId}${Math.floor(Math.random() * 900) + 100}`
                  )
                })
              } else if (params?.combineMasterSubAccount) {
                secondLevelRowIndex += 1
                secondLevelGroup.unMasterGroup = true
                secondLevelGroup.list = []
                secondLevelGroup.rowIndex = secondLevelRowIndex
              }
            })
          })
        }
        this.treasuryBalanceList = data
        this.treasuryBalanceSimplificationList = data.list.map(this.removeCurrencyList)
        return {
          list: this.treasuryBalanceSimplificationList,
          total: data.total
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取衍生品持仓列表
     * @param {string} entityId 主体id
     * @param {TradeCalculatePositionParamsType} params
     * @property {number} params.date 日期
     * @property {string} params.subGroup
     * @property {string} params.sort
     */
    async fetchTradeCalculatePositionList(entityId: string, params?: TradeCalculatePositionParamsType) {
      try {
        const { data } = await AccountsApi.getTradeCalculatePosition(entityId, params)
        // this.isInitTreasuryBalance = false
        this.tradeCalculatePositionList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取DeFi持仓列表
     * @param {string} entityId 主体id
     * @param {DeFiHoldingPositionParamsType} params
     * @property {number} params.date 日期
     * @property {string} params.group
     * @property {string} params.subGroup
     */
    async fetchDeFiHoldingList(entityId: string, params: DeFiHoldingPositionParamsType) {
      try {
        const { data } = await AccountsApi.getDeFiHoldingList(entityId, params)
        this.deFiHoldingList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * defi 过滤器数据初始化
     * @param {string} entityId 项目id
     */
    async deFiHoldingFilterDataInit(entityId: string) {
      const deFiHoldingProtocolList = new Promise((resolve, reject) => {
        AccountsApi.getDeFiHoldingProtocolList(entityId)
          .then((response) => {
            this.deFiHoldingProtocolList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            return reject(e)
          })
      })

      const deFiHoldingTypeList = new Promise((resolve, reject) => {
        AccountsApi.getDeFiHoldingTypeList(entityId)
          .then((response) => {
            this.deFiHoldingTypeList = response.data
            return resolve(response.data)
          })
          .catch((e) => {
            this.deFiHoldingTypeList = []
            return reject(e)
          })
      })

      try {
        const response = await Promise.allSettled([
          this.fetchEntityAccountList(entityId),
          deFiHoldingTypeList,
          deFiHoldingProtocolList
        ])
        return Promise.resolve(response)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取Account详情
     * @param {string} entityId 主体id
     * @param {number} entityAccountId 主体id
     */
    async fetchEntityAccountDetail(entityId: string, entityAccountId: string) {
      try {
        const { data } = await AccountsApi.getEntityAccountDetail(entityId, entityAccountId)
        this.accountDetail = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取counterparty详情
     * @param {string} entityId 主体id
     * @param {number} counterpartyId 主体id
     */
    async fetchCounterpartyDetail(entityId: string, counterpartyId: string) {
      try {
        const { data } = await AccountsApi.getCounterpartDetail(entityId, counterpartyId)
        this.counterpartyDetail = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取联系人列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     */
    async fetchContactList(entityId: string, params: object) {
      try {
        const { data } = await AccountsApi.getContactUnNameList(entityId, params)
        this.contactList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取对手方列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {number} params.page 页码
     * @property {number} params.limit 每页条数
     */
    async fetchCounterpartList(entityId: string, params: object) {
      try {
        const { data } = await AccountsApi.getCounterpartyList(entityId, params)
        this.counterpartList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取entity添加的CounterpartTag
     * @param {string} entityId 主体id
     */
    async fetchCounterpartTagList(entityId: string) {
      try {
        const { data } = await AccountsApi.getCounterpartTag(entityId, {})
        this.counterpartTagList = data
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 获取entity账户列表
     * @param {string} entityId 主体id
     * @param {object} params
     * @property {boolean} params.withDeleted 是否包含被删除账户
     */
    async fetchEntityAccountList(entityId: string, params?: object) {
      try {
        const { data } = await AccountsApi.getEntityAccountList(entityId, params)
        this.accountList = data?.length ? flatMap(data, 'list') : []
        return this.accountList
      } catch (error) {
        return Promise.reject(error)
      }
    },
    /**
     * 编辑余额列表筛选器
     * @param {number} entityId 主体id
     * @param {object} data 过滤器数据
     */
    editAccountFilter(entityId: string, data: RecentlyBalanceParamsType) {
      const currentIndex = this.accountFilterList.findIndex(
        (item: TreasuryAccountFilterType) => item.entityId === entityId
      )
      const filterLength = Object.keys(data).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.accountFilterList.splice(currentIndex, 1)
        } else {
          this.accountFilterList.splice(currentIndex, 1, {
            entityId,
            total: filterLength,
            data
          })
        }
      } else if (filterLength) {
        this.accountFilterList.push({ entityId, total: filterLength, data })
      }
    },
    /**
     * 编辑衍生品列表筛选器
     * @param {number} entityId 主体id
     * @param {object} data 过滤器数据
     */
    editDerivativeFilterList(entityId: string, data: TradeCalculatePositionParamsType) {
      const currentIndex = this.derivativeFilterList.findIndex(
        (item: TradeCalculatePositionFilterType) => item.entityId === entityId
      )
      const filterLength = Object.keys(data).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.derivativeFilterList.splice(currentIndex, 1)
        } else {
          this.derivativeFilterList.splice(currentIndex, 1, {
            entityId,
            total: filterLength,
            data
          })
        }
      } else if (filterLength) {
        this.derivativeFilterList.push({ entityId, total: filterLength, data })
      }
    },
    /**
     * 编辑Defi持仓筛选器
     * @param {number} entityId 主体id
     * @param {object} data 过滤器数据
     */
    editDeFiPositionsFilterList(entityId: string, data: DeFiHoldingPositionParamsType) {
      const currentIndex = this.deFiPositionsFilterList.findIndex(
        (item: DeFiHoldingPositionFilterType) => item.entityId === entityId
      )
      const filterLength = Object.keys(data).length
      const totalCount = Object.keys(data).filter((key) => !['group', 'date', 'subGroup'].includes(key)).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.deFiPositionsFilterList.splice(currentIndex, 1)
        } else {
          this.deFiPositionsFilterList.splice(currentIndex, 1, {
            entityId,
            total: totalCount,
            data
          })
        }
      } else if (filterLength) {
        this.deFiPositionsFilterList.push({ entityId, total: totalCount, data })
      }
    },
    /**
     * 编辑余额校验任务列表筛选器
     */
    editTreasuryBalanceVerifyTaskFilterList(
      entityId: string,
      type: string,
      data: TreasuryBalanceVerifyTaskFilterFilterItemType
    ) {
      const currentIndex = this.treasuryBalanceVerifyTaskFilterList.findIndex(
        (item: TreasuryBalanceVerifyTaskFilterFilterType) => item.entityId === entityId && item.type === type
      )
      const filterLength = Object.keys(data).length

      if (currentIndex !== -1) {
        if (!filterLength) {
          this.treasuryBalanceVerifyTaskFilterList.splice(currentIndex, 1)
        } else {
          this.treasuryBalanceVerifyTaskFilterList.splice(currentIndex, 1, {
            entityId,
            total: filterLength,
            type,
            data
          })
        }
      } else if (filterLength) {
        this.treasuryBalanceVerifyTaskFilterList.push({ entityId, type, total: filterLength, data })
      }
    }
  },
  getters: {},
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'accountFilterList',
          'derivativeFilterList',
          'deFiPositionsFilterList',
          'treasuryBalanceVerifyTaskFilterList'
        ]
      }
    ]
  }
})
